/* eslint-disable no-console */

import { z } from 'zod';

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const env = import.meta.env ?? process.env; // so it can be used in both vite and node
const isProdMode = env.MODE === 'production';

/* eslint-disable camelcase*/
export const appConfigSchema = z.object({
    /** url of hapigator */
    api_url: z.string().url().default('https://api-dev.affilae.com'),
    productor_url: z
        .string()
        .url()
        .default('https://productor-preprod.affilae.com/api/feed/affilae'),
    demo_url: z.string().url().default('https://demo.affilae.com'),
    mfaEnabled: z.boolean().default(true),
    /** url of showcase website, marketplace. Can work without it but link to it won't work */
    website_url: z.string().url().default('https://affilae.com/'),
    /** To enable sentry reporting*/
    sentry: z
        .object({
            dsn: z.string().url().optional(),
            env: z.string().default('local'),
        })
        .default({}),

    stripe: z
        .object({
            public_key: z.string().default('pk_test_F8WPF1DviHDEIW4tWCtRfqPL'),
        })
        .default({}),

    /** enable reporting of sign-up as conversion in Affilae, optional  */
    tracking: z
        .object({
            cpl_key: z.string().optional(),
            cpa_key: z.string().optional(),
        })
        .optional(),
    pollingInterval: z
        .object({
            // Why there are different polling interval?
            // it's a story of compromise between server performance and client data fidelity / latency
            // for some counter is important to have a low latency and be notified right away (ex: message), so we need a low pulling interval.
            // and for other it's much less important to have a low latency, a count true to the one stored on the server, so a bigger interval is alright.

            small: z
                .number()
                .int()
                .positive()
                .default(5 * 1000),
            big: z
                .number()
                .int()
                .positive()
                .default(3 * 60 * 1000),
        })
        .default({}),
    displayDemoLogin: z.boolean().default(false),
    cookieSuffix: z.string().trim().optional(),

    // ex: onboarding redirection. Useful for testing
    disableRedirect: z.boolean().default(!isProdMode),
    secureCookie: z.boolean().default(isProdMode),

    gtm: z
        .object({
            auth: z.string(),
            previewEnv: z.string(),
            containerId: z.string(),
        })
        .optional(),
    gadsId: z.string().optional(),
});

export type AppConfig = z.infer<typeof appConfigSchema>;
export type AppConfigInput = Partial<AppConfig> | undefined;

const logIfDevelopment = (message: string) => {
    if (import.meta.env.MODE === 'development') {
        console.log(message);
    }
};

let _configError: Error | undefined;
export function getConfigError() {
    return _configError;
}

export function initConfig() {
    const inputConfig = globalThis.REACTOR_CONFIG_INPUT ?? globalThis.CONFIG; // fallback to old config
    if (inputConfig) {
        logIfDevelopment('Initiating config from `globalThis.REACTOR_CONFIG_INPUT`');
    }
    let resolvedConfig: AppConfig;
    try {
        resolvedConfig = appConfigSchema.parse(inputConfig ?? {});
    } catch (error) {
        console.error(
            'Error parsing config `globalThis.REACTOR_CONFIG_INPUT`\nOnly valid values will be applied',
        );
        console.error(error);
        _configError = error; // store the error to be able to report it to Sentry later
        resolvedConfig = gracefulParseConfig(inputConfig);
    }
    identifyUnknownKeys(inputConfig);
    Object.freeze(resolvedConfig); // make it immutable to prevent accidental modifications at runtime
    logIfDevelopment('Config resolved and saved in `globalThis.REACTOR_CONFIG`');
    globalThis.REACTOR_CONFIG = resolvedConfig;
    globalThis.CONFIG = resolvedConfig;
}

function identifyUnknownKeys(inputConfig: AppConfigInput) {
    if (inputConfig == null) {
        return;
    }
    const unknownKeys = Object.keys(inputConfig).filter((key) => !isConfigKey(key));
    if (unknownKeys.length > 0) {
        console.warn(`Unknown keys in config: ${unknownKeys.join(', ')}`);
    }
}

/** parse config but only keep valid values */
function gracefulParseConfig(inputConfig: AppConfigInput): AppConfig {
    const defaultConfig = appConfigSchema.parse({});
    if (inputConfig == null) {
        return defaultConfig;
    }

    const config = defaultConfig;

    for (const [key, value] of Object.entries(inputConfig)) {
        if (isConfigKey(key)) {
            const res = appConfigSchema.shape[key].safeParse(value);
            if (res.success) {
                config[key] = res.data;
            }
        }
    }

    return config;
}

function isConfigKey(key: string): key is keyof AppConfig {
    return Object.prototype.hasOwnProperty.call(appConfigSchema.shape, key);
}

import { queryOptions } from '@tanstack/react-query';
import moment from 'moment';

import apiListAffiliateProfiles from '@api/admin/affiliateProfiles/list';

const ms = (time: string) => moment.duration(time).asMilliseconds();

export const affiliateProfilesListQuery = queryOptions({
    queryKey: ['affiliate-profiles-list'],
    queryFn: () => apiListAffiliateProfiles(['id', 'name']),
    staleTime: ms('5m'),
    refetchInterval: ms('1h'),
    gcTime: ms('1w'),
});

import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { initCurrentAdvertiser } from '@actions/advertiser';
import {
    cancelSubscription,
    closeAllDialogs,
    listPaymentMethods,
    subscribe,
    updateSubscription,
    updateSubscriptionAdvertiserLoading,
    verify3ds,
} from '@actions/advertiserBilling';
import { addFlash } from '@actions/common';
import _ from 'lodash';

import { LoadingButton } from '@mui/lab';

import { Dialog, DialogActions, DialogContent, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import LoyaltyIcon from '@mui/icons-material/Loyalty';

import { DialogHeader } from '@components/generic/DialogHeader';
import { Tooltip } from '@components/generic/Tooltip';

import { useOldAppState } from '@hooks/useOldAppState';

import { EUCountryCodes } from '@libs/lib_countries';
import currencyFormatter from '@libs/lib_currency';
import { useAppDispatch, useAppSelector } from '@libs/reduxHooks';

import fetchCurrentAdvertiserData from '@api/advertiser/fetchCurrentAdvertiserData';

export const yearlyDiscountPercent = 20; // could evolve to read from api;

const stripe = Stripe(CONFIG.stripe.public_key);
let card = null;

export default function SubscribeDialog() {
    const { t } = useTranslation();

    const oldApp = useOldAppState().app;

    const advertiser = useAppSelector((state) => state.advertiser.currentAdvertiser!);
    const isAdvertiserEU = EUCountryCodes.includes(advertiser.country);
    const isOpen = useAppSelector((state) => state.advertiserBillingReducer.subscribeDialog.isOpen);
    const isLoading = useAppSelector(
        (state) => state.advertiserBillingReducer.subscribeDialog.loading,
    );
    const fields = useAppSelector((state) => state.advertiserBillingReducer.subscribeDialog.fields);
    const action = useAppSelector((state) => state.advertiserBillingReducer.subscribeDialog.action);
    const paymentIntentSecret = useAppSelector(
        (state) => state.advertiserBillingReducer.subscribeDialog.paymentIntentSecret,
    );
    const paymentIntentStatus = useAppSelector(
        (state) => state.advertiserBillingReducer.subscribeDialog.paymentIntentStatus,
    );
    const plans = useAppSelector((state) => state.common.plans);

    const dispatch = useAppDispatch();
    function handleSuccess(message: string) {
        dispatch(closeAllDialogs());
        dispatch(addFlash('success', message));
    }

    const [state, setState] = useState({
        fields: _.cloneDeep(fields) ?? {},
        showAllPlans: false,
        showCancelSubscription: false,
        confirmationText: '',
        isCardComplete: false,
        error3ds: false,
    });

    const [billingPeriod, setBillingPeriod] = useState(advertiser.billingPeriod);

    useEffect(() => {
        if (_.isEmpty(state.fields.currency) && !_.isEmpty(advertiser)) {
            setState((state) => ({
                ...state,
                fields: {
                    ...state.fields,
                    currency: isAdvertiserEU ? 'EUR' : advertiser.currency,
                    plan: advertiser.plan,
                },
            }));
        }
    }, [state.fields.currency, advertiser, isAdvertiserEU]);

    useEffect(() => {
        if (action === 'subscribe' && paymentIntentStatus === 'requires_action') {
            handleCardPayment();
        }
        if (action === 'update' && paymentIntentStatus === 'requires_action') {
            confirmCardPayment();
        }
        if (paymentIntentStatus === 'succeeded') {
            setState((state) => ({ ...state, error3ds: false }));
            handleSuccess(t('billing_subscription_' + action + '_dialog_success'));
        }
    }, [paymentIntentStatus]);

    useEffect(() => {
        if (isOpen) {
            setState((state) => ({
                ...state,
                showCancelSubscription: false,
                confirmationText: '',
                isCardComplete: false,
            }));

            if (action === 'subscribe') {
                card = stripe.elements().create('card', {
                    hidePostalCode: true,
                });
                // We wait for the dialog to exist in DOM
                setTimeout(function () {
                    card.mount('#card-element');
                    card.on('change', function (data) {
                        setState((state) => ({ ...state, isCardComplete: data.complete }));
                    });
                }, 100);
            }
        }
    }, [isOpen]);

    function pushConversion() {
        if (
            CONFIG.tracking?.cpa_key != null &&
            typeof window.AeTracker != 'undefined' &&
            typeof window._ae != 'undefined'
        ) {
            const AE = {};
            AE.Conversion = {};
            AE.key = CONFIG.tracking.cpa_key;
            AE.Conversion.id = advertiser.id;
            AE.Conversion.subid = '';
            AE.Conversion.payment = 'online';
            AE.Conversion.amount = advertiser.price / 100;
            AE.Conversion.voucher = '';

            window.AE = AE;
            window.AeTracker.sendConversion();
        }
    }

    async function confirmCardPayment() {
        const result = await stripe.confirmCardPayment(paymentIntentSecret);
        if (result.error) {
            // todo reset to come back here
            // Display error.message in your UI.
            setState((state) => ({ ...state, error3ds: true }));
            dispatch(addFlash('error', 'Something went wrong, contact our support@affilae.com'));
        } else {
            // The payment has succeeded. Display a success message.
            setState((state) => ({ ...state, error3ds: false }));
            dispatch(verify3ds());
            handleSuccess(t('billing_subscription_' + action + '_dialog_success'));
        }
    }

    async function handleCardPayment() {
        const result = await stripe.handleCardPayment(paymentIntentSecret, card);
        if (result.error) {
            // todo reset to come back here
            // Display error.message in your UI.
            setState((state) => ({ ...state, error3ds: true }));
            dispatch(addFlash('error', 'Something went wrong, contact our support@affilae.com'));
        } else {
            // The payment has succeeded. Display a success message.
            setState((state) => ({ ...state, error3ds: false }));
            dispatch(verify3ds());
            handleSuccess(t('billing_subscription_' + action + '_dialog_success'));
        }
    }

    async function handleSubmit() {
        dispatch(updateSubscriptionAdvertiserLoading());

        // Retry 3ds if fails on subscribe
        if (action === 'subscribe' && state.error3ds && paymentIntentStatus === 'requires_action') {
            handleCardPayment();
            return;
        }

        // Retry 3ds if fails on update
        if (action === 'update' && state.error3ds && paymentIntentStatus === 'requires_action') {
            confirmCardPayment();
            return;
        }

        if (state.showCancelSubscription) {
            dispatch(cancelSubscription());
            setTimeout(updateGlobalStates, 6000);
            return;
        }

        if (action === 'subscribe') {
            if (advertiser.paymentMethod === 'stripe') {
                const result = await stripe.createPaymentMethod('card', card);
                if (result.error) {
                    // Inform the customer that there was an error.
                    const errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                } else {
                    // Send the token to your server TODO
                    dispatch(
                        subscribe(
                            result.paymentMethod,
                            state.fields.plan,
                            state.fields.currency,
                            billingPeriod,
                        ),
                    );
                    pushConversion();
                }
            } else if (advertiser.paymentMethod === 'bankwire') {
                dispatch(subscribe(null, state.fields.plan, state.fields.currency, billingPeriod));
            }
        }

        if (action === 'update') {
            // no action if plan isn't changed
            if (state.fields.plan === advertiser.plan) {
                handleSuccess(t('billing_subscription_update_dialog_success'));
            }
            dispatch(updateSubscription(state.fields.plan));
        }

        // Delayed UI refresh to retrieve accurate subscription status
        setTimeout(updateGlobalStates, 25000);

        async function updateGlobalStates() {
            dispatch(listPaymentMethods());
            const { currentAdvertiser } = await fetchCurrentAdvertiserData();
            oldApp.setState({ advertiser: currentAdvertiser });
            dispatch(initCurrentAdvertiser(currentAdvertiser));
        }
    }

    function selectPlan(plan: string) {
        setState((state) => ({
            ...state,
            fields: {
                ...state.fields,
                plan: plan,
            },
        }));
    }

    if (_.isEmpty(advertiser) || _.isEmpty(plans)) {
        return null;
    }

    const formHasChanged = state.fields.plan !== advertiser.plan;
    return (
        <Dialog
            data-testid='subscribeDialog'
            open={isOpen}
            fullWidth={true}
            className='subscriptionDialog'
            onClose={() => dispatch(closeAllDialogs())}
            sx={{
                '.MuiDialog-paper': {
                    maxWidth: '700px',
                },
            }}
        >
            <DialogHeader
                title={
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                        {t('billing_subscription_' + action + '_dialog_title')}
                        {action === 'subscribe' && !isAdvertiserEU && (
                            <Select
                                id='selectCurrency'
                                value={state.fields.currency}
                                onChange={(event) =>
                                    setState((state) => ({
                                        ...state,
                                        fields: { ...state.fields, currency: event.target.value },
                                    }))
                                }
                            >
                                <MenuItem value='EUR'>EUR</MenuItem>
                                <MenuItem value='USD'>USD</MenuItem>
                                <MenuItem value='GBP'>GBP</MenuItem>
                            </Select>
                        )}
                    </div>
                }
                icon={LoyaltyIcon}
            />

            <DialogContent>
                {action === 'subscribe' && (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <Typography fontSize={'0.8rem'} sx={{ width: '150px', textAlign: 'right' }}>
                            Monthly
                        </Typography>
                        <Switch
                            name='billingPeriod'
                            color='secondary'
                            checked={billingPeriod === 'yearly'}
                            onChange={(event) =>
                                setBillingPeriod(event.target.checked ? 'yearly' : 'monthly')
                            }
                        />
                        <Typography fontSize={'0.8rem'} sx={{ width: '150px', color: '#419ed8' }}>
                            Yearly (save {yearlyDiscountPercent}%)
                        </Typography>
                        {/* both label have same width to center switch horizontally */}
                    </Box>
                )}

                <div
                    className={
                        'plansWrapper ' + (state.showCancelSubscription ? 'disabledArea' : '')
                    }
                >
                    {Object.values(plans)
                        .filter((plan) => plan.title.toLowerCase() !== 'scale')
                        .map((plan) => {
                            const isSelected = state.fields.plan === plan.stripe_id;
                            const isOpened =
                                advertiser.plan === 'on-demand' ||
                                action === 'update' ||
                                state.fields.plan === plan.stripe_id ||
                                state.showAllPlans;
                            const currency = _.toLower(state.fields.currency!);
                            const price = plan.price.monthly[currency] as number;

                            return (
                                <Collapse in={isOpened} key={plan.title}>
                                    <Paper
                                        elevation={isSelected ? 5 : 1}
                                        onClick={() => selectPlan(plan.stripe_id)}
                                        className={'planWrapper ' + (isSelected ? 'selected' : '')}
                                    >
                                        <div>
                                            <div className='planTitle'>
                                                {billingPeriod === 'yearly' ? (
                                                    <Trans
                                                        i18nKey='billing_subscription_plan_title_yearly'
                                                        values={{
                                                            plan: plan.title,
                                                            discountPercent: yearlyDiscountPercent,
                                                        }}
                                                    >
                                                        ___{' '}
                                                        <span style={{ color: '#419ed8' }}>
                                                            save %
                                                        </span>{' '}
                                                        __
                                                    </Trans>
                                                ) : (
                                                    t('billing_subscription_plan_title_monthly', {
                                                        plan: plan.title,
                                                    })
                                                )}
                                            </div>
                                            <div className='quotasInfo'>
                                                {t('billing_subscription_quota_clicks', {
                                                    count: plan.quota_clicks,
                                                })}{' '}
                                                <br />
                                                {t('billing_subscription_quota_affiliates_users')}
                                            </div>
                                        </div>
                                        <div className='price'>
                                            <div className='primary'>
                                                {currencyFormatter(
                                                    currency,
                                                    (price / 100) *
                                                        (billingPeriod === 'yearly'
                                                            ? 1 - yearlyDiscountPercent / 100
                                                            : 1),
                                                ) +
                                                    '/' +
                                                    t('M')}
                                            </div>
                                            <div className='secondary'>
                                                {billingPeriod === 'yearly' ? (
                                                    <>
                                                        {t('billing_subscription_billed_at')}
                                                        <span
                                                            style={{
                                                                textDecorationLine: 'line-through',
                                                            }}
                                                        >
                                                            {currencyFormatter(
                                                                currency,
                                                                (price * 12) / 100,
                                                            )}
                                                        </span>
                                                        <span style={{ fontWeight: 800 }}>
                                                            {currencyFormatter(
                                                                currency,
                                                                (price *
                                                                    12 *
                                                                    (1 -
                                                                        yearlyDiscountPercent /
                                                                            100)) /
                                                                    100,
                                                            ) + t('generic_by_year')}
                                                        </span>
                                                    </>
                                                ) : (
                                                    t('billing_subscription_billed_monthly')
                                                )}
                                            </div>
                                        </div>
                                    </Paper>
                                </Collapse>
                            );
                        })}

                    <div className='seeAllPlansWrapper'>
                        {action === 'subscribe' && advertiser.plan !== 'on-demand' && (
                            <span
                                className='seeAllPlans'
                                onClick={() =>
                                    setState((state) => ({
                                        ...state,
                                        showAllPlans: !state.showAllPlans,
                                    }))
                                }
                            >
                                {state.showAllPlans
                                    ? t('billing_subscription_hide_other_plans')
                                    : t('billing_subscription_show_all_plans')}
                            </span>
                        )}
                        <a href={t('url_pricing')} target='_blank' rel='noreferrer'>
                            {t('billing_subscription_pricing_hint_link')}
                        </a>
                    </div>
                </div>

                {action === 'subscribe' && advertiser.paymentMethod === 'stripe' && (
                    <div className='stripeCardElementWrapper'>
                        <div>{t('billing_subscription_add_credit_card')}</div>
                        <form method='post' id='payment-form'>
                            <div className='form-row'>
                                <label htmlFor='card-element'>{/* Credit or debit card */}</label>
                                <div id='card-element'></div>
                                <div id='card-errors' role='alert'></div>
                            </div>
                        </form>
                    </div>
                )}

                <Collapse className='cancelFormWrapper' in={state.showCancelSubscription}>
                    {t('billing_subscription_update_dialog_cancel_disclaimer')}
                    <TextField
                        id='fieldConfirmDelete'
                        className='MUI-TextField'
                        margin='normal'
                        onChange={(e) =>
                            setState((state) => ({ ...state, confirmationText: e.target.value }))
                        }
                        fullWidth
                    />
                </Collapse>
            </DialogContent>

            <DialogActions>
                {action === 'update' && !state.showCancelSubscription && (
                    <Button
                        color='error'
                        onClick={() =>
                            setState((state) => ({ ...state, showCancelSubscription: true }))
                        }
                        style={{
                            marginRight: 'auto',
                        }}
                    >
                        {t('billing_subscription_update_dialog_cancel')}
                    </Button>
                )}
                <Button
                    color='quiet'
                    onClick={() =>
                        state.showCancelSubscription
                            ? setState((state) => ({ ...state, showCancelSubscription: false }))
                            : dispatch(closeAllDialogs())
                    }
                >
                    {t('cancel')}
                </Button>
                <Tooltip title={formHasChanged === false ? (t('generic_noChange') as string) : ''}>
                    <span>
                        <LoadingButton
                            variant='contained'
                            loading={isLoading}
                            disabled={
                                isLoading ||
                                (action === 'subscribe' && !state.isCardComplete) ||
                                (action === 'update' &&
                                    !state.showCancelSubscription &&
                                    formHasChanged === false) ||
                                (state.showCancelSubscription &&
                                    state.confirmationText !==
                                        t(
                                            'billing_subscription_update_dialog_cancel_confirmation_text',
                                        ))
                            }
                            onClick={() => handleSubmit()}
                        >
                            {state.showCancelSubscription
                                ? t('billing_subscription_update_dialog_cancel')
                                : t('submit')}
                        </LoadingButton>
                    </span>
                </Tooltip>
            </DialogActions>
        </Dialog>
    );
}
